export const ON_LOAD = 'ON_LOAD'
export const IS_MAIN_LOADING = 'isMainLoading'
export const SET_IS_MAIN_LOADING = 'SET_IS_MAIN_LOADING'
export const LOAD_ESSENTIAL_STRUCTURES = 'LOAD_ESSENTIAL_STRUCTURES'
export const GET_CITIES_RESULT = 'GET_CITIES_RESULT'
export const GET_STREETS_RESULT = 'GET_STREETS_RESULT'
export const IS_SCROLL_WANTED = 'isScrollWanted'
export const SET_IS_SCROLL_WANTED = 'SET_IS_SCROLL_WANTED'
export const IS_MOCKUP_MODE = 'isMockupMode'
export const SET_IS_MOCKUP_MODE = 'SET_IS_MOCKUP_MODE'

export const emptyCity = {cityId: 0, name: ''}
export const emptyStreet = {cityId: 0, streetId: 0, name: ''}

export const LOCATING_LINE_CODE_PARAM = {
  1: 'קוד קו',
  2: '4 ספרות כרטיס אשראי או 4 ספרות אחרונות הוראת  קבע',
  3: 'טלפון נייד',
  4: 'כתובת + שם משפחה + פרטי',
}

export const GPON_BUILDING_TYPE = {
  'A': 'בית צמוד קרקע',
  'B': 'בניין מגורים עד 16 דירות',
  'C': 'בניין מגורים 17 דירות ומעלה',
}

export const GPON_DISTRIBUTION_TYPE = {
  1: 'פנימית כולל ורטיקל',
  2: 'חיצונית',
  3: 'צמוד קרקע',
  4: 'פנימית ללא ורטיקל',
}
export const TECHNICIAN_TYPE = {
  1: 'לא נדרש טכנאי',
  2: 'טכנאי מתואם, יש להפעיל ממשק שיבוץ טכנאי',
  3: 'נדרש טכנאי ללא תיאום לקוח רק בנחושת',
  6: 'ללא טכנאי בזק בהתקנת GPON',
}

export const LINE_BROKEN_CODE = 1000  // this const appears in mockup and frontend
export const LINE_BROKEN_TEXT = 'בדיקת שיברון'

export const ELECTRIC_CHECK_CODE = 10  // this const appears in mockup and frontend
export const ELECTRIC_CHECK_TEXT = 'בדיקה חשמלית'

export const GPON_CHECK_CODE = 31  // this const appears in mockup and frontend
export const GPON_CHECK_TEXT = 'בדיקת קו ג׳יפון'


export const CHECK_LINE_ACTION_CODE = {
  1: 'בדיקת קו אינטרנט',
  2: 'אתחול פורט אינטרנט',
  [ELECTRIC_CHECK_CODE]: ELECTRIC_CHECK_TEXT,
  11: 'אחזור נתוני רשת: תג תיבה',
  21: 'מעבר לחבילה תפעולית זמנית',
  22: 'חזרה לחבילת לקוח המוזמנת',
  [GPON_CHECK_CODE]: GPON_CHECK_TEXT,
  32: 'אתחול ONT',
  33: 'אתחול ETH',
  34: 'תיקון אי התאמות',
  [LINE_BROKEN_CODE]: [LINE_BROKEN_TEXT],
}

export const SERVICE_ORDER_TYPE_CODE = {
  667: 'איטיות',
  668: 'ניתוקים',
  669: 'חוסר סנכרון',
  670: 'גישה לאינטרנט',
  1089: 'החלפת תיבה באותה כתובת לבקשת ספק בתשלום',
  1092: 'WS איטיות - עד שקע ראשון',
  1093: 'WS ניתוקים - עד שקע ראשון',
  1094: 'WS חוסר סינכרון - עד שקע ראשון',
  1095: 'WS גישה לאינטרנט - עד שקע ראשון',
  1196: 'WS ניתוקים GPON',
  1197: 'WS חוסר סנכרון GPON',
  1198: 'WS גישה לאינטרנט GPON',
  1199: 'WS ניתוקים - עד שקע ראשון GPON',
  1200: 'WS חוסר סינכרון - עד שקע ראשון GPON',
  1201: 'WS גישה לאינטרנט - עד שקע ראשון GPON',
  1202: 'WS טיפול בתקלה משותף בזק וספק עד שקע ראשון GPON',
  1203: 'תקלה בתג המוקצה במפצל בלובי (צד רשת בזק) GPON',
  1204: 'תקלה בסיב האנכי, בין המפצל בלובי ובין התיבה בקומתית GPON',
}

export const TASK_STATUS_CODE = {
  1: 'פתוח',
  2: 'משובץ',
  3: 'סגור',
  4: 'מבוטל',
}

export const TASK_CLOSE_STATE = {
  1: 'תקין',
  2: 'לא תקין',
  3: 'הסתיים בהצלחה',
  4: 'לא הסתיים',
}

export const TASK_TYPE_CODE = {
  10825: 'אין גישה לתיבה',
  10826: 'מגבלת בטיחות',
  10827: 'ספק/לקוח ביקש ביטול',
  10828: 'ספק/לקוח ביקש דחיה',
  10832: 'אין גישה לתיבה',
  10833: 'מגבלת בטיחות',
  10834: 'בית לקוח סגור',
  10838: 'לקוח לא קיים בכתובת',
  10839: 'ספק/לקוח ביקש ביטול',
  10840: 'ספק/לקוח ביקש דחיה',
  11024: 'הסתיים טיפול בזק, יש לתאם טכנאי מחדש',
  11687: 'GPON אין גישה לתיבה',
  11688: 'GPON בית לקוח סגור',
  11689: 'GPON - הסתיים טיפול בזק, יש לתאם טכנאי מחדש',
  11690: 'GPON לקוח לא קיים בכתובת',
  11691: 'GPON מגבלת בטיחות',
  11692: 'GPON ספק/לקוח ביקש ביטול',
  11693: 'GPON ספק/לקוח ביקש דחיה',
}
export const SERVICE_ORDER_STATUS_CODE = {
  1: 'פתוח',
  2: 'בוטל',
  3: 'סגור',
  7: 'בהקמה',
}
