import {SET_IS_LOADING} from 'Redux/consts'
import {GET_CITIES_RESULT, GET_STREETS_RESULT, LOAD_ESSENTIAL_STRUCTURES, ON_LOAD, SET_IS_MAIN_LOADING, SET_IS_MOCKUP_MODE, SET_IS_SCROLL_WANTED} from "./consts";

export function onLoad() {
    return {
        type: ON_LOAD
    }
}
export function setIsLoading(isLoading){
    return {
        type: SET_IS_LOADING,
        isLoading
    }
}
export function loadEssentialStructures(){
    return {
        type: LOAD_ESSENTIAL_STRUCTURES
    }
}
export function getCitiesResult(cities){
    return {
        type: GET_CITIES_RESULT,
        cities
    }
}
export function getStreetsResult(streets){
    return {
        type: GET_STREETS_RESULT,
        streets
    }
}
export function setIsScrollWanted(isScrollWanted){
    return {
        type: SET_IS_SCROLL_WANTED,
        isScrollWanted
    }
}
export function setIsMainLoading(isMainLoading){
    return {
        type: SET_IS_MAIN_LOADING,
        isMainLoading
    }
}
export function setIsMockupMode(value){
    return {
        type: SET_IS_MOCKUP_MODE,
        value
    }
}
