import {Paper} from '@mui/material'
import classes from 'classnames'
import styles from 'Components/Orders/LocateCustomerInfra/index.module.scss'
import {GPON_BUILDING_TYPE, GPON_DISTRIBUTION_TYPE, LOCATING_LINE_CODE_PARAM} from 'Containers/AppGeneral/consts'
import * as React from 'react'

export default function LocateCustomerInfraResult({customerInfraInfo}) {
  return <Paper className={styles.ws1results} elevation={3}>
    <div className={classes({[styles.good]:customerInfraInfo?.messageCode<=2,[styles.bad]:customerInfraInfo?.messageCode>2})}>
      {customerInfraInfo?.messageDesc}
    </div>
    {customerInfraInfo?.messageCode <= 2 && <>
    {customerInfraInfo?.lineCode && <div>
        <div>קוד קו</div>
        <div>{customerInfraInfo?.lineCode}</div>
      </div>}
      <div>
      <div>דירוג חבילה</div>
      <div>{customerInfraInfo?.packageRateCode}</div>
      </div>
      <div>
      <div>תיאור חבילה (נחושת)</div>
      <div>{customerInfraInfo?.packageRateMessage}</div>
      </div>
      <div>
      <div>מודם בבעלות בזק</div>
      <div>{customerInfraInfo?.bezeqOwnModem ? 'כן' : 'לא'}</div>
      </div>
    {customerInfraInfo?.preSaleModemType && <div>
      <div>סוג מודם</div>
      <div>{customerInfraInfo?.preSaleModemType}</div>
      </div>}
    {customerInfraInfo?.locatingLineCodeParam && <div>
      <div>כיצד אותר</div>
      <div>{LOCATING_LINE_CODE_PARAM[customerInfraInfo?.locatingLineCodeParam]}</div>
      </div>}
      <div>
      <div>קיים gpon</div>
      <div>{Number(customerInfraInfo?.gponInfo?.gponExists) === 1 ? 'כן' : 'לא'}</div>
      </div>
    {customerInfraInfo?.gponInfo?.gponBuildingType && <div>
      <div>סוג בניין</div>
      <div>{GPON_BUILDING_TYPE[customerInfraInfo?.gponInfo?.gponBuildingType]}</div>
      </div>}
    {customerInfraInfo?.gponInfo?.distributionType && <div>
      <div>סוג פריסת תשתית</div>
      <div>{GPON_DISTRIBUTION_TYPE[customerInfraInfo?.gponInfo?.distributionType]}</div>
      </div>}
    </>}
  </Paper>
}
