import axios from "axios";
import getConfig from 'Config/config'
import {disconnect} from 'Containers/Auth/actions'
import {TENANT_ID, TOKEN} from 'Containers/Auth/consts'
import {getCookie} from 'Helpers/cookies'
import {LineBreaksTransformStream} from 'Helpers/LineBreaksTransformStream'
import {toastr} from 'Helpers/toastr'
import {formatBackendError} from 'Helpers/utils'
import {AIRGAP_AUTH} from 'Redux/consts'
import store from 'Redux/store'

export const axiosInstance = axios.create({
  withCredentials: getConfig().authorizationMethod === AIRGAP_AUTH,
  headers: {
    "Access-Control-Request-Private-Network": true,
  },
})

export default class RequestAPI {
  constructor(serviceUrl) {
    this.serviceUrl = serviceUrl
  }
  get = async (path, config) => {
    try {
      const response = await axiosInstance.get(this.serviceUrl + RequestAPI.attachTenantId(path), {headers: RequestAPI.getHeaders(), ...config})
      if (Object.hasOwn(response?.data, 'isSuccess') && !response.data.isSuccess) {
        const errorStr = formatBackendError(response?.data)
        toastr.error(errorStr)
        throw new Error(errorStr)
      }
      return response?.data
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message)
        }
      }
      throw (e)
    }
  }

  post = async (path, body, config) => {
    try {
      const response = await axiosInstance.post(this.serviceUrl + RequestAPI.attachTenantId(path), body, {headers: RequestAPI.getHeaders(), ...config})
      if (Object.hasOwn(response?.data, 'isSuccess') && !response.data.isSuccess) {
        const errorStr = formatBackendError(response?.data)
        toastr.error(errorStr)
        throw new Error(errorStr)
      }
      return response?.data
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message)
        }
      }
      throw (e)
    }
  }

  postWithFormData = async (path, body, progressUpdates) => {
    const data = new FormData()
    Object.keys(body).forEach(key => data.append(key, typeof body[key] === 'object' && !(body[key] instanceof Blob) && !(body[key] instanceof File) ? JSON.stringify(body[key]) : body[key]))
    try {
      const response = await axiosInstance.post(this.serviceUrl + RequestAPI.attachTenantId(path), data, {
        headers: {
          ...RequestAPI.getHeaders(),
        },
        ...RequestAPI.progressUpdatesManager(progressUpdates),
        timeout: 300000,
      })
      return response?.data
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message, {autoClose:10000})
        }
      }
      throw (e)
    }
  }

  postWithStreamResponse = async (path, body, publishChunk = async () => {}) => {
    const data = new FormData()
    Object.keys(body).forEach(key => data.append(key, typeof body[key] === 'object' && !(body[key] instanceof Blob) && !(body[key] instanceof File) ? JSON.stringify(body[key]) : body[key]))
    try {
      await publishChunk()
      const response = await fetch(this.serviceUrl + RequestAPI.attachTenantId(path), {
        method: 'POST',
        body: data,
        headers: {
          ...RequestAPI.getHeaders(),
        },
        timeout: 300000,
      })

      const splittedStream = await response.body.pipeThrough(new LineBreaksTransformStream())
      const reader = splittedStream.getReader()

      let responseData = ''
      let isDone = false
      let statusCodeOverride
      while (!isDone) {
        const readerChunk = await reader.read()
        isDone = readerChunk.done
        let chunk = new TextDecoder().decode(readerChunk.value)
        const statusCodeOverrideInfo = chunk.match(/^(.*)statusCodeOverride=(\d+)(.*)$/)
        if (statusCodeOverrideInfo) {
          const before = statusCodeOverrideInfo[1]
          statusCodeOverride = statusCodeOverrideInfo[2]
          const after = statusCodeOverrideInfo[3]
          chunk = before+after
        }
        responseData += chunk
        await publishChunk(chunk,statusCodeOverride)
        if (statusCodeOverride) {
          const ex = new Error(chunk)
          ex.code = statusCodeOverride
          throw ex
        }
      }
      return responseData
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message, {autoClose:10000})
        }
      }
      throw (e)
    }
  }

  put = async (path, body) => {
    try {
      const response = await axiosInstance.put(this.serviceUrl + RequestAPI.attachTenantId(path), body, {headers: RequestAPI.getHeaders()})
      if (Object.hasOwn(response?.data, 'isSuccess') && !response.data.isSuccess) {
        const errorStr = formatBackendError(response?.data)
        toastr.error(errorStr)
        throw new Error(errorStr)
      }
      return response?.data
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message)
        }
      }
      throw (e)
    }
  }

  delete = async (path, body) => {
    try {
      const response = await axiosInstance.delete(this.serviceUrl + RequestAPI.attachTenantId(path), {headers: RequestAPI.getHeaders(), data: body})
      if (Object.hasOwn(response?.data, 'isSuccess') && !response.data.isSuccess) {
        const errorStr = formatBackendError(response?.data)
        toastr.error(errorStr)
        throw new Error(errorStr)
      }
      return response?.data
    } catch (e) {
      console.error(e)
      if (e?.response?.status === 401) {
        store.dispatch(disconnect())
      } else {
        if (e?.response?.data || e?.message) {
          toastr.error(e?.response?.data || e.message)
        }
      }
      throw (e)
    }
  }

  static getHeaders() {
    const headers = {}
    const token = getCookie(TOKEN)
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    headers['Access-Control-Request-Private-Network'] = true
    return headers
  }

  static attachTenantId(path) {
    const tenantId = getCookie(TENANT_ID)
    if (tenantId) {
      let supplementPrefix = path.indexOf('?') > -1 ? '&' : '?'
      return `${path}${supplementPrefix}selectedTenant=${tenantId}`
    } else {
      return path
    }
  }

  static progressUpdatesManager(progressUpdates) {
    return progressUpdates ? {
      onUploadProgress: progressUpdates,
    } : {}
  }
}
