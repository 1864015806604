import apiServer, {apiMockupServer} from 'Api/Backend'
import {LINE_BROKEN_CODE} from 'Containers/AppGeneral/consts'
import {createCheckLineOutcomeWs5Result, createCheckLineRequestWs4Result, setCreateCheckLineOutcomeWs5IsLoadingAndId, setCreateCheckLineRequestWs4IsLoading} from 'Containers/CheckLines/actions'
import {loadCheckLinesResult, setLoadCheckLinesIsLoading} from 'Containers/CheckLines/actions'
import {toastr} from 'Helpers/toastr'
import {sleep} from 'Helpers/utils'
import {takeEvery, put, call, takeLeading} from 'redux-saga/effects'
import {CREATE_CHECK_LINE_OUTCOME_WS5, CREATE_CHECK_LINE_REQUEST_WS4, LOAD_CHECK_LINES} from "./consts";

function *createCheckLineRequestWs4Saga({request}) {
    console.log('gizim', 3464657, {request});

    yield put(setCreateCheckLineRequestWs4IsLoading(true))
    try {
        yield put(createCheckLineRequestWs4Result(null))
        let result
        if (Number(request?.actionCode) === Number(LINE_BROKEN_CODE)) {
            result = yield call(apiServer.post, 'Fault/Broken', {orderId: request?.orderId})
        } else {
            result = yield call(apiServer.get, `Line/CheckLine/${request?.orderId}/${request?.actionCode}`)
        }
        yield put(createCheckLineRequestWs4Result(result))
    } catch (e) {
        console.error(e)
    }
    yield put(setCreateCheckLineRequestWs4IsLoading(false))
}
function *loadCheckLinesSaga({limit, offset, keyword}) {
    yield put(setLoadCheckLinesIsLoading(true))
    try {
        const result = yield call(apiMockupServer.get, 'LoadCheckLines', {params: {limit,  offset, keyword}})
        yield call(sleep, 1000)
        yield put(loadCheckLinesResult(result))

    } catch (e) {
        console.error(e)
    }
    yield put(setLoadCheckLinesIsLoading(false))
}
function *createCheckLineOutcomeWs5Saga({id}) {
    yield put(setCreateCheckLineOutcomeWs5IsLoadingAndId(id))
    try {
        const result = yield call(apiMockupServer.put, 'CreateCheckLineOutcome', {id:Number(id)})
        yield call(sleep, 2000)
        if (!result.response) {
            toastr.info('טרם התקבלה תשובה מבזק')
        } else {
            yield put(createCheckLineOutcomeWs5Result(result))
        }
    } catch (e) {
        console.error(e)
    }
    yield put(setCreateCheckLineOutcomeWs5IsLoadingAndId(false))
}

export default function* CheckLinesSaga() {
    yield takeEvery(CREATE_CHECK_LINE_REQUEST_WS4, createCheckLineRequestWs4Saga)
    yield takeEvery(CREATE_CHECK_LINE_OUTCOME_WS5, createCheckLineOutcomeWs5Saga)
    yield takeLeading(LOAD_CHECK_LINES, loadCheckLinesSaga)
}
