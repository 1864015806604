import EditOrderPanel from 'Components/Orders/EditOrderPanel/EditOrderPanel'
import {setIsScrollWanted} from 'Containers/AppGeneral/actions'
import {getCities, getIsScrollWanted, getStreets} from 'Containers/AppGeneral/selectors'
import {setCreateCheckLineIsLoading} from 'Containers/CheckLines/actions'
import {getCreateCheckLineIsLoading} from 'Containers/CheckLines/selectors'
import {retrieveTagWs14, activateGponWs15, createOrderWs2, createTicketForOrder, finalizeOrderWs12, retrieveOrderWs3, createChecklineForOrder, openUserTicketsByOrder, cancelOrder, closeOrderAndRemoveLine} from 'Containers/Orders/actions'
import {
  getActivateGponIsLoading,
  getRetrieveTagIsLoading,
  getCreateOrderIsLoading,
  getCustomerInfraInfo,
  getFinalizeOrderIsLoading,
  getLocateByGisIsLoading,
  getLocateCustomerInfraIsLoading,
  getRetrieveOrderIsLoading,
  getRetrievedTagInfo, getRedirectToCreateTicketIsLoading, getRedirectToCreateCheckLineIsLoading, getRedirectToShowUserTicketsIsLoading, getCancelOrderIsLoading, getCloseOrderAndRemoveLineIsLoading
} from 'Containers/Orders/selectors'
import {getCreateTicketIsLoading} from 'Containers/Tickets/selectors'
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    createOrderWs2: (redirect) => dispatch(createOrderWs2(redirect)),
    setIsScrollWanted: value => dispatch(setIsScrollWanted(value)),
    retrieveOrder: orderId => dispatch(retrieveOrderWs3(orderId)),
    finalizeOrderWs12: (orderId) => dispatch(finalizeOrderWs12(orderId)),
    retrieveTagWs14: ({...args}) => dispatch(retrieveTagWs14({...args})),
    activateGponWs15: ({...args}) => dispatch(activateGponWs15({...args})),
    createTicketForOrder: (redirect, orderId, orderFormValues) => dispatch(createTicketForOrder(redirect, orderId,  orderFormValues)),
    createChecklineForOrder:(redirect, orderId) => dispatch(createChecklineForOrder(redirect, orderId)),
    openUserTicketsByOrder: (redirect, orderId) => dispatch(openUserTicketsByOrder(redirect, orderId)),
    cancelOrder: (orderId) => dispatch(cancelOrder(orderId)),
    closeOrderAndRemoveLine: (orderId) => dispatch(closeOrderAndRemoveLine(orderId)),
  }
};
const mapStateToProps = (state) => {
  return {
    locateCustomerInfraIsLoading: getLocateCustomerInfraIsLoading(state),
    customerInfraInfo: getCustomerInfraInfo(state),
    cities: getCities(state),
    streets: getStreets(state),
    retrievedTagInfo: getRetrievedTagInfo(state),
    createOrderIsLoading: getCreateOrderIsLoading(state),
    isScrollWanted: getIsScrollWanted(state),
    retrieveOrderIsLoading: getRetrieveOrderIsLoading(state),
    finalizeOrderIsLoading: getFinalizeOrderIsLoading(state),
    getRetrieveTagIsLoading: getRetrieveTagIsLoading(state),
    activateGponIsLoading: getActivateGponIsLoading(state),
    locateByGisIsLoading: getLocateByGisIsLoading(state),
    redirectToCreateTicketIsLoading: getRedirectToCreateTicketIsLoading(state),
    redirectToCreateChecklineIsLoading: getRedirectToCreateCheckLineIsLoading(state),
    redirectToShowUserTicketsIsLoading: getRedirectToShowUserTicketsIsLoading(state),
    cancelOrderIsLoading: getCancelOrderIsLoading(state),
    closeOrderAndRemoveLineIsLoading: getCloseOrderAndRemoveLineIsLoading(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderPanel)
