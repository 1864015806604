import {isEndpointOK} from 'Helpers/netBlockageDetector'
import {AIRGAP_AUTH} from 'Redux/consts'
let backend = ''

async function resolveBackend() {
    if (backend) {
        return backend
    }
    const currentDomain = window.location.hostname.split('.')[0]
    switch (currentDomain) {
        case 'localhost':
            backend = 'https://ferret-web.geocell.systems'
            break
        case '127.0.0.1':
            backend = 'https://ferret-web.geocell.systems'
            break
        case 'bez-dev':
            backend = 'https://squirrel.geocell.systems'
            break
        case 'bez-test':
            backend = 'https://ferret-web.geocell.systems'
            break
        case 'bez':
            backend = 'https://adonis-web.geocell.systems'
            break
        case 'bezeq':
            backend = 'https://bezeq.kma.net.il'
            break
        default:
            throw `could not resolve which backend to use for ${window.location.hostname}`
    }
    console.log('initial backend',{backend})

    if (backend === 'https://adonis-web.geocell.systems') {
        const isOk = await isEndpointOK('https://adonis-web.geocell.systems/api/userInfo')
        if (!isOk) {
            backend = 'https://adonis.geocell.systems'
        }
    }
    if (backend === 'https://ferret-web.geocell.systems') {
        const isOk = await isEndpointOK('https://ferret-web.geocell.systems/api/userInfo')
        if (!isOk) {
            backend = 'https://ferret.geocell.systems'
        }
    }
    console.log('final backend',{backend})
    return backend
}

resolveBackend()

const getConfig = () => ({
    googleClientId: '',
    firebaseConfig: {
        apiKey: "AIzaSyCsUZ1CwJmaSMGjj3q5GtcEZjrdxEtgyj0",
        authDomain: "geobez-93b3a.firebaseapp.com",
        projectId: "geobez-93b3a",
        storageBucket: "geobez-93b3a.appspot.com",
        messagingSenderId: "375437298311",
        appId: "1:375437298311:web:012bda41b93da3a5ce0622"
    },
    backendServer: backend,
    apiServer: `${backend}/api/`,
    swaggerServer: `${backend}/swagger/`,
    apiMockupServer: `${backend}/api/mockup/`,
    // authorizationMethod: FIREBASE_AUTH,
    authorizationMethod: AIRGAP_AUTH,
    isProduction: backend.startsWith('https://adonis') || backend.startsWith('https://bezeq.kma.net.il'),
    hasSwagger: !backend.startsWith('https://bezeq.kma.net.il'),
    providerEmail: 'mohammad@kma.net.il',
})

export const redirectToProviderIfNeeded = async () => {
    var regex = /(https?:\/\/bezeq\.kma\.net\.il|https?:\/\/localhost:3000|https?:\/\/bez-test\.geocell\.systems|https?:\/\/bez\.geocell\.systems)?\/$/
    if (regex.test(window.location.href)) {
        console.log('redirecting from ', window.location.href, ' to /kma')
        window.location.href = '/kma'
    }
}

export default getConfig

