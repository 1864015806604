import UpdateOrderIcon from '@mui/icons-material/Save'
import GetScheduleIcon from '@mui/icons-material/CalendarMonthSharp'
import UpdateIcon from '@mui/icons-material/Update'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import {Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, Paper, Select} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import classes from 'classnames'
import Mirror from 'Components/Generic/Mirror'
import SpinnerButton from 'Components/Generic/SpinnerButton'
import {formatBackendDate, formatBezeqDate, formatTimeSlotRange, MenuProps} from 'Components/Tickets/ticketsUtils'
import {emptyCity, emptyStreet, SERVICE_ORDER_TYPE_CODE} from 'Containers/AppGeneral/consts'
import TicketRetrievals from 'Containers/Tickets/TicketsRetrievals/TicketsRetrievals'
import {objectToArray} from 'Helpers/arrays'
import useNumericDigitsValidator from 'Hooks/useNumericDigitsValidator'
import useRedirect from 'Hooks/useRedirect'
import * as React from 'react'
import {useCallback, useEffect, useRef, useState} from 'react'
import styles from './EditTicketPanel.module.scss'

const EditTicketPanel = ({ ticketId,
                           orderId,
                           formValues,
                           setFormValues,
                           createTicketIsLoading,
                           createTicketWs8,
                           singleTicket, retrieveTicket,
                           retrieveTicketIsLoading,
                           cities = [],
                           streets = [],
                         }) => {

  const formRef = useRef()
  const [isCreateTicketSubmitOk, setIsCreateTicketSubmitOk] = useState(false)
  const [handleLineCodeChange, lineCodeErrors] = useNumericDigitsValidator(formValues?.lineCode)
  const [handleContactPhoneChange, contactPhoneErrors] = useNumericDigitsValidator()
  const [selectedCityRow, setSelectedCityRow] = useState(formValues?.cityRow || emptyCity)
  const [selectedStreetRow, setSelectedStreetRow] = useState(formValues?.streetRow || emptyStreet)
  const [streetsFiltered, setStreetsFiltered] = useState([])
  const {
    timeIntervals,
    slaTo,
    bezeqOrderId,
  } = singleTicket || {}
  console.log('gizim', 6549775, 'formValues',formValues);

  const saveFormValues = useCallback((_ref) => {
    var data = new FormData(_ref?.current || formRef.current)
    let formObject = Object.fromEntries(data.entries())
    Object.keys(formObject).forEach(formKey => {
      if (formObject[formKey] === 'true@@@') {
        formObject[formKey] = true
      }
    })
    setFormValues(formObject)
  }, [setFormValues])

  const redirect = useRedirect()

  const createTicketFormSubmit = (e) => {
    e.preventDefault()
    saveFormValues()
    createTicketWs8(redirect, formValues)
  }

  useEffect(() => {
    const streetsFiltered = streets?.filter((st) => st.cityId === selectedCityRow?.cityId).concat([emptyStreet])
    setStreetsFiltered(streetsFiltered)
  }, [selectedCityRow, streets])

  const validationObject = {}

  function monitor(formVar) { // runs inside render cycle
    const key = Object.keys(formVar)[0]
    const value = formVar[key]
    validationObject[key] = !!value
    return !!value
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const atLeastOneError = !!Object.values(validationObject).find(item => item)
    setIsCreateTicketSubmitOk(!atLeastOneError)
  })

  const isFormDisabled = false
  if (!cities?.length > 1 || !streets?.length > 1) {
    return <div>ממתין לטעינת ערים ורחובות</div>
  }

  return <div className={classes(styles.ticketEditorContainer)}>
    <Paper elevation={3}>
      <Mirror>
        <form ref={formRef} onSubmit={createTicketFormSubmit}>
          <div className={styles.inARow}>
            <TextField label="קוד קו"
                       type="string"
                       name="lineCode"
                       onChange={handleLineCodeChange}
                       error={monitor({lineCodeErrors})}
                       helperText={lineCodeErrors}
                       inputProps={{maxLength: 10, readOnly: !!ticketId}}
                       defaultValue={formValues?.lineCode}
                       disabled={isFormDisabled}
                       // required={true}
                       variant={ticketId ? 'filled' : 'outlined'}
                       onInvalid={(e) => {
                         e.target.setCustomValidity('שדה זה הינו חובה');
                       }}
                       onInput={e => e.target.setCustomValidity('')}
            />
            {bezeqOrderId && <TextField label="מספר הזמנה בבזק" variant="filled" name="bezeqOrderId" value={bezeqOrderId} inputProps={{readOnly: true}}/>}
          </div>
          <TextField label="הערות ספק"
                     name="providerComments"
                     multiline
                     rows={2}
                     inputProps={{maxLength: 1024}}
                     defaultValue={formValues?.providerComments}
                     disabled={isFormDisabled}
          />
          <FormControl>
            <InputLabel>סוג הוראת עבודה</InputLabel>
            <Select name="serviceOrderTypeCode"
                    defaultValue={formValues?.serviceOrderTypeCode || ''}
                    disabled={isFormDisabled}
                    MenuProps={MenuProps}
                    label='סוג הוראת עבודה' // create legend white space
            >
              {objectToArray(SERVICE_ORDER_TYPE_CODE).map(item =>
                (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>)
              )}
            </Select>
          </FormControl>
          <FormControlLabel label="תקלה חוזרת"
                            name="repeatedFault"
                            className={styles.checkBoxStyleAssurer}
                            control={<Checkbox defaultChecked={formValues?.repeatedFault}
                                               inputProps={{value: "true@@@"}}
                            />}
                            disabled={isFormDisabled}
          />


          {orderId && <TextField label="מספר הוראת עבודה לתקלה קיימת"
                     type="number"
                     name="serviceOrderNum"
                     defaultValue={formValues?.serviceOrderNum}
                     disabled={true}
          />}
          {orderId && <TextField label="מספר הזמנה אצלנו"
                                 variant="filled"
                                 type="number"
                                 name="orderId"
                                 defaultValue={orderId}
                                 inputProps={{readOnly: true}}
          />}

          {timeIntervals ? <>
            <FormControl>
              <InputLabel>בחירת מועד ביקור טכנאי</InputLabel>
              <Select name="estimatedTimeFromTo"
                      defaultValue={formValues?.estimatedTimeFromTo || ''}
                      disabled={isFormDisabled}
                      MenuProps={MenuProps}
                      label="בחירת מועד ביקור טכנאי"// create legend white space
              >
                {timeIntervals.map((item, index) =>
                  (<MenuItem key={index} value={item}>{formatTimeSlotRange(item)}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </> : !ticketId && <>
            <TextField label="תאריך ביצוע מקדמי"
                       name="requestDateFrom"
                       type="datetime-local"
                       defaultValue={formValues?.requestDateFrom}
                       disabled={isFormDisabled}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
            <TextField label="תאריך ביצוע אחרון"
                       name="requestDateTo"
                       type="datetime-local"
                       defaultValue={formValues?.requestDateTo}
                       disabled={isFormDisabled}
                       InputLabelProps={{
                         shrink: true,
                       }}
            />
          </>}
          <div className={styles.inARow}>
            <Autocomplete
              disablePortal
              label="שם יישוב"
              name="cityRow"
              value={selectedCityRow}
              isOptionEqualToValue={(option, value) => {
                return option.cityId === value.cityId
              }}
              onChange={(e, value) => {
                setSelectedStreetRow(emptyStreet);
                setSelectedCityRow(value)
              }}
              options={cities.concat([emptyCity])}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label="עיר"/>}
              disabled={true}
            />
            <Autocomplete
              disablePortal
              label="שם רחוב"
              name="streetRow"
              value={selectedStreetRow}
              isOptionEqualToValue={(option, value) => {
                return option.streetId === value.streetId
              }}
              onChange={(e, value) => setSelectedStreetRow(value)}
              options={streetsFiltered}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label="רחוב"/>}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.streetId + option.cityId}>
                    {option.name}
                  </li>
                );
              }}
              disabled={true}
            />
            <TextField label="מספר בית"
                       type="number"
                       name="houseNum"
                       defaultValue={formValues?.houseNum}
                       disabled={true}
            />
          </div>

          <div className={styles.inARow}>
            <TextField label="קומה"
                       name="floor"
                       inputProps={{maxLength: 2}}
                       defaultValue={formValues?.floor}
                       disabled={isFormDisabled}
            />
            <TextField label="דירה"
                       name="apartment"
                       inputProps={{maxLength: 2}}
                       defaultValue={formValues?.apartment}
                       disabled={isFormDisabled}
            />
          </div>
          <div className={styles.inARow}>
            <TextField label="שם איש קשר"
                       name="contactName"
                       defaultValue={formValues?.contactName}
                       disabled={isFormDisabled}
            />
            <TextField label="סלולרי איש קשר"
                       type="string"
                       name="contactPhoneNumber"
                       onChange={handleContactPhoneChange}
                       error={monitor({contactPhoneErrors})}
                       helperText={contactPhoneErrors}
                       inputProps={{maxLength: 11}}
                       defaultValue={formValues?.contactPhoneNumber}
                       disabled={isFormDisabled}
            />
          </div>

          {slaTo && <TextField variant="filled" label="S.L.A" value={formatBackendDate(slaTo)} inputProps={{readOnly: true}}/>}
          <div className={styles.ticketButtons}>
            {ticketId && <SpinnerButton
              onClick={() => retrieveTicket(ticketId)}
              disabled={retrieveTicketIsLoading}
              isLoading={retrieveTicketIsLoading}
              startIcon={<UpdateIcon/>}
            >אחזור סטטוס
            </SpinnerButton>}
            {(ticketId || timeIntervals) && <SpinnerButton disabled={createTicketIsLoading || !isCreateTicketSubmitOk}
                                                           isLoading={createTicketIsLoading}
                                                           type="submit"
                                                           startIcon={<UpdateOrderIcon/>}
            >עדכון תקלה</SpinnerButton>}
            {!(ticketId || timeIntervals) && <SpinnerButton disabled={createTicketIsLoading || !isCreateTicketSubmitOk}
                                                            isLoading={createTicketIsLoading}
                                                            type="submit"
                                                            startIcon={<GetScheduleIcon/>}
            >יצירה/קבלת מועדי טכנאי פנויים</SpinnerButton>}
            {ticketId && <SpinnerButton
              startIcon={<DeleteIcon/>}
            >ביטול תקלה
            </SpinnerButton>}
            {ticketId && <SpinnerButton
              startIcon={<GetScheduleIcon/>}
            >קבלת מועדים חדשים
            </SpinnerButton>}
            {ticketId && <SpinnerButton
              startIcon={<CloseIcon/>}
            >סגירת משימת ספק
            </SpinnerButton>}
          </div>
        </form>
        {singleTicket && <TicketRetrievals singleTicket={singleTicket}/>}
      </Mirror>
    </Paper>
  </div>
}

export default EditTicketPanel
